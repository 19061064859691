// Here you can add other styles
.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

//img table
.img-table {
  width: 100%;
  height: auto;
  max-width: 50px;
}

// drawer filter

.filter-close {
  position: absolute;
  right: 10px;
  top: 10px;
}

.filter-label {
  font-size: 1.5rem;
  color: #000;
}
